
const TYPE_RHS = 1;
const TYPE_SHS = 2;
const TYPE_CHS = 3;
const TYPE_FLAT_BAR = 4;
const TYPE_SQUARE_BAR = 5;
const TYPE_ROUND_BAR = 6;
const TYPE_EQUAL_ANGLE=7;
const TYPE_UNEQUAL_ANGLE=8;

const PROFILES = [
    {   
        Key: TYPE_RHS, 
        Name: "RHS",
        Sections: [
            {name: "50X25", h: 50, w: 50, wt: [1.6,2,2.5,3]}
        ]
    },
    {
        Key: TYPE_SHS, 
        Name: "SHS",
        Sections: [
            {name: "50X50", h: 50, w: 50, wt: [1.6,2,2.5,3,4,5]}
        ]
    },
    {
        Key: TYPE_CHS, 
        Name: "CHS"
    },
    {
        Key: TYPE_EQUAL_ANGLE, 
        Name: "EA"
    },
    {
        Key: TYPE_UNEQUAL_ANGLE, 
        Name: "UEA"
    },
    {
        Key: TYPE_FLAT_BAR, 
        Name: "FB"
    },
    {
        Key: TYPE_SQUARE_BAR, 
        Name: "SQ"
    },
    {
        Key: TYPE_ROUND_BAR, 
        Name: "RB",
        Sections: [
            {name: "Ø20", h: 20, w: 20, wt: []}
        ]
    }
];

function getSectionsFromProfile(key){
    for(let i=0; i<PROFILES.length; i++){
        if(PROFILES[i].Key==Number(key)){
            var list = [];
            const {Sections} = PROFILES[i];
            for(let j=0; j<Sections.length; j++){
                const {wt,h,w,name} = Sections[j];
                if(wt.length==0){
                    list.push({
                        name: name,
                        key: `${key}|${h}|${w}|0`
                    })
                }
                else{
                    for(let k=0; k<wt.length; k++){
                        list.push({
                            name: name+` - ${wt[k].toFixed(1)}`,
                            key: `${key}|${h}|${w}|${wt[k]}`
                        })
                    }
                }
            }
            return list;
        }
    }
    return [];
}

function getProfileFromKey(type, key){
    for(let i=0; i<PROFILES.length; i++){
        const {Sections} = PROFILES[i];
        if(PROFILES[i].Key==type){
            for(let j=0; j<Sections.length; j++){
                const {wt,h,w,name} = Sections[j];
                if(wt.length==0){
                    var checkKey = `${PROFILES[i].Key}|${h}|${w}|0`;
                    if(checkKey==key){
                        return {
                            profile: {
                                key: PROFILES[i].Key,
                                name: PROFILES[i].Name
                            },
                            section: {
                                name: name,
                                height: h,
                                width: w,
                                wall_thickness: 0
                            }
                        }
                    }
                }
                else
                {
                    for(let k=0; k<wt.length; k++){
                        var checkKey = `${PROFILES[i].Key}|${h}|${w}|${wt[k]}`;
                        if(checkKey==key){
                            return {
                                profile: {
                                    key: PROFILES[i].Key,
                                    name: PROFILES[i].Name
                                },
                                section: {
                                    name: name,
                                    height: h,
                                    width: w,
                                    wall_thickness: wt[k]
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null;
}

class Profile
{
    _type = null;
    _height = null;
    _width = null;
    _length = null;
    _wall_thickness = null;
    _density = 7849.999987;
    _rad = 0;

    constructor(type, height, width, wall_thickness){
        if(typeof type !== 'number'){
            type = Number(type);
        }
        this._type = type;
        this._height = height;
        this._width = width;
        this._length = 0;
        this._wall_thickness = wall_thickness;

        this._rad = wall_thickness*1;

        if(wall_thickness > 5 && (this._type==TYPE_RHS || this._type==TYPE_SHS)){
            this._rad = wall_thickness*1.5;
        }
    }
    set length(l){
        
        this._length=l;
    }
    get length(){
        return this._length;
    }
    set rad(r){
        this._rad=r;
    }
    get rad(){
        return this._rad;
    }
    set density(d){
        this._density = d;
    }
    get density(){
        return this._density;
    }
    get weight(){
        return this.volume * (this._density/1000000000);
    }
    get volume(){      
        return this.section * this._length;
    }
    get section(){
        switch(this._type){
            case TYPE_RHS:
            case TYPE_SHS:
                var extRad = this._rad + this._wall_thickness;
                var extRadVolumeInvert = (extRad*2*extRad*2) - (Math.PI * extRad*extRad);
                var intRadVolumeInvert =  (this._rad*2 * this._rad*2) - (Math.PI * this._rad*this._rad);
                var extVolume = (this._height * this._width) - extRadVolumeInvert;
                var intVolume = ((this._height-(2*this._wall_thickness)) * (this._width - (2*this._wall_thickness))) - intRadVolumeInvert;
                return extVolume - intVolume;
            break;
            case TYPE_CHS:
                var extRad = (this._height/2);
                var intRad = extRad-this._wall_thickness;

                var extVolume = extRad*extRad*Math.PI;
                var intVolume = intRad*intRad*Math.PI;
                return extVolume - intVolume;
            break;
            case TYPE_ROUND_BAR:
                var extRad = (this._height/2);
                var extVolume = extRad*extRad*Math.PI;
                return extVolume;
            break;
            case TYPE_SQUARE_BAR:
                return this._height*this._width;
            break;
            case TYPE_UNEQUAL_ANGLE:
            case TYPE_EQUAL_ANGLE:
                var extVolume = this._height*this._width;
                var intVolume = (this._height-this._wall_thickness)*(this._width-this._wall_thickness);
                var intRad = (this._rad*this._rad) - ((this._rad*this._rad*Math.PI)/4);
                intVolume -= intRad;
                return extVolume - intVolume;

            break;
        }
        return this._height*this._width;
    }
    json = () =>{
        return {
            t: this._type,
            h: this._height,
            w: this._width,
            l: this._length,
            wt: this._wall_thickness,
            d: this._density,
            r: this._rad
        }
    }
}
function fromJSON(json){
    let obj =  new Profile(json.t, json.h, json.w, json.wt);
    obj.length = json.l;
    obj.density = json.d;
    obj.rad = json.r;
    return obj;

}
function RHS(height, width, wall_thickness){
    return new Profile(TYPE_RHS, height, width, wall_thickness);
}
function SHS(height, wall_thickness){
    return new Profile(TYPE_SHS, height, height, wall_thickness);
}
function CHS(diameter, wall_thickness){
    return new Profile(TYPE_CHS, diameter, diameter, wall_thickness);
}
function RB(diameter){
    return new Profile(TYPE_ROUND_BAR, diameter, diameter, 0);
}
function SB(height, width){
    return new Profile(TYPE_SQUARE_BAR, height, width, 0);
}
function EA(height, wall_thickness){
    return new Profile(TYPE_EQUAL_ANGLE, height, height, wall_thickness);
}
function UEA(height,width, wall_thickness){
    return new Profile(TYPE_UNEQUAL_ANGLE, height, width, wall_thickness);
}
export {RHS, SHS, CHS, RB, SB, EA, UEA, fromJSON, PROFILES, Profile, getSectionsFromProfile, getProfileFromKey};