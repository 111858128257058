import React, { useState, useCallback, useEffect } from 'react';
import {Link, Redirect} from "react-router-dom";
import { DatabaseRequest } from '../../Components/DatabaseRequest';
import { CutListItem, CutListRate} from './Components/CutListItem';
import { LabourListItem } from './Components/LabourListItem';
import { PriceItem } from './Components/PriceItem';
export const QuoteEdit = () => {
    const COMPONENT_NEWLINE = 0;
    const COMPONENT_LASER = 1;
    const COMPONENT_MATERIAL = 2;
    const COMPONENT_LABOUR = 3;
    const COMPONENT_PURCHASED = 4;
    const COMPONENT_CONSUMABLE = 5;

    const [newType, setNewType] = useState(0);
    const [components, setComponents] = useState([]);
    const [time, setTime] = useState(0);
    //var components = [];
    /*
    Laser

    Cut List
        Profile
    
    Labour / Machine Time

    Purchased Components

    Fasteners / Consumables

    */
    var load = `[{"type":"2","data":{"rate":150,"item":{"material":"GR250MS","profile":"2","section":"2|50|50|4"},"lengths":[{"weight":38.42224353968876,"length":"7000","qty":"1"},{"weight":5.488891934241251,"length":"1000","qty":"1"}],"kerf":2,"stock":8000,"stock_lengths":1,"stock_weight":43.91113547393001,"length_weight":43.93309104166698,"efficiency":100.05,"IsFullLength":false,"IsPerKg":true},"price":6589.963656250046}]`;
    useEffect(()=>{
       // setComponents(JSON.parse(load));
    },[])
    useEffect(()=>{
        if(newType!=0){
            addRow(newType);
            setNewType(0);
        }

    }, [newType]);

    function saveQuote(){
        console.log(JSON.stringify(components));
    }
    
    function toDollar(price){
        let dollar = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        return dollar.format(price);
        return "$   " + price.toFixed(2);
    }
    function addRow(type){
        const updateComponents = [...components];
        updateComponents.push({
            type: type,
            data: null,
            price: 0
        });
        setComponents(updateComponents);

    } 
    function table(){
        return (
            <>
            <table className="table table-striped">
                <thead className="thead-dark">
                    <tr>
                    <th scope="col" style={{width: '20px'}}>#</th>
                    <th scope="col" style={{width: '12rem'}}>Item</th>
                    <th scope="col">Description</th>
                    <th scope="col" className="text-right pr-4" style={{width: '12rem'}}>Price</th>
                    </tr>
                </thead>
                <tbody>
                {
                    components.map((row, i)=>{
                        return (
                        <React.Fragment key={i}>
                            {componentRow(row, i)}
                        </React.Fragment>
                        );
                    })
                }    
                {newRow(components.length)}
                </tbody>
            </table>
            </>
        )
    }
    function newRow(i){
        return (
            <tr>
                <td>{i+1}</td>
                <td>
                    {componentSelect(newType, (e)=>{setNewType(e.target.value);}, false)}
                </td>
                <td>
                    
                </td>
                <td>
                    <input type="text" className='form-control text-right' disabled={true} value="0.00"/>
                </td>
            </tr>
        )
    }
    function updateData(line, data){
        const updateComponents = [...components];
        const lineItem = updateComponents.find((a, i) => {return i === line});
        if(lineItem){
            lineItem.data = data;
            setComponents(updateComponents);
            //components = updateComponents;
        }
    }
    function setPrice(line, price){
        const updateComponents = [...components];
        const lineItem = updateComponents.find((a, i) => {return i === line});
        if(lineItem){
            lineItem.price = price;
            setComponents(updateComponents);
            //components = updateComponents;
        }
    }
    function componentRow(row, i){
        return (
            <>
            <tr>
                <td>{i+1}</td>
                <td>
                    {componentSelect(row.type, ()=>{}, true)}
                </td>
                <td>
                    {row.type==COMPONENT_MATERIAL?(<CutListRate line={i} initData={components[i].data} setPrice={setPrice} updateData={updateData}/>):(<></>)}
                    {row.type==COMPONENT_LABOUR?(<LabourListItem line={i} initData={components[i].data} setPrice={setPrice} updateData={updateData}/>):(<></>)}
                    {row.type==COMPONENT_PURCHASED?(<PriceItem line={i} initData={components[i].data} setPrice={setPrice} updateData={updateData}/>):(<></>)}
                    {row.type==COMPONENT_CONSUMABLE?(<PriceItem line={i} initData={components[i].data} setPrice={setPrice} updateData={updateData}/>):(<></>)}
                </td>
                <td>
                    <input type="text" className='form-control text-right' value={toDollar(row.price)} readOnly={true}/>
                </td>
            </tr>
            <tr>
                <td colSpan={4}>
                    {row.type==COMPONENT_MATERIAL?(<CutListItem line={i} initData={components[i].data} setPrice={setPrice} updateData={updateData}/>):(<></>)}
                </td>
            </tr>
            </>
        );
    }
    function componentSelect(value, cb, disabled){
        return (
        <select className="form-control" value={value} disabled={disabled} onChange={(e)=>{cb(e)}}>
                <option value={COMPONENT_NEWLINE}>New Line</option>
                {disabled?(<option value={COMPONENT_LASER}>Laser</option>):(<></>)}
                <option value={COMPONENT_MATERIAL}>Material</option>
                <option value={COMPONENT_LABOUR}>Labour</option>
                <option value={COMPONENT_PURCHASED}>Purchased</option>
                <option value={COMPONENT_CONSUMABLE}>Consumables</option>
        </select>
        );
    }
    return (
    <>
            <main role="main" className="container">
                <h2><i className="fe fe-dollar-sign"></i> Quote</h2>
                <div className='row mb-3'>
                    <div className='col-4'>
                        <label>Attention </label>
                        <input type='text' className='form-control' />
                    </div>
                    <div className='col float-right p-0 m-0'>
                        <button className="btn btn-success float-right" type="button" onClick={(e)=>{saveQuote()}}>Save Details</button>
                    </div>

                </div>

                <div className="row">
                    {table()}

                </div>
            </main>
    </>);
}


