import React, { useState, useCallback, useEffect } from 'react';

import CutList from './CutList';
import {Profile, PROFILES, getSectionsFromProfile, getProfileFromKey} from './Profiles';
import MATERIALS from './Materials';



function getUnitPricing(Rate, TotalWeight, PartWeight, Lengths, Efficiency, IsFullLength, IsPerKg){
    //console.log(Rate, TotalWeight, PartWeight, Lengths, Efficiency, IsFullLength, IsPerKg)
    var value = 0;
    if(IsPerKg){
        value = IsFullLength?Rate*TotalWeight:Rate*PartWeight;
    }
    else{
        value = IsFullLength?Rate*Lengths:Rate*(Efficiency/100)*Lengths;
    }

    return isNaN(value)?0:value;
}



export const CutListItem = ({line, initData, updateData, setPrice}) =>{
    const [item, setItem] = useState({
        material: null,
        profile: null,
        section: null,
    });

    const [IsPerKg, setIsPerKg] = useState(true);
    const [IsFullLength, setIsFullLength] = useState(false);

    const [results, setResults] = useState(null);
    const [lengths, setLengths] = useState([]);

    const [kerf, setKerf] = useState(2);
    const [stock, setStock] = useState(8000);

    useEffect(()=>{
        if(initData!=null){
            setItem(initData.item);
            setLengths(initData.lengths);
            setKerf(initData.kerf);
            setStock(stock);
        }
    }, []);


    useEffect(()=>{
        var _results = runCutList();
        setResults(_results);
        if(_results!=null){
            var obj = {
                ...initData,
                item: item,
                lengths: lengths,
                kerf: kerf,
                stock: stock,
                stock_lengths: _results.lengths,
                stock_weight: _results.stockWeight,
                length_weight: _results.partWeight,
                efficiency: _results.efficiency,
                IsFullLength: IsFullLength,
                IsPerKg: IsPerKg
            };
            updateData(line,obj);
            setPrice(line, getUnitPricing(obj.rate?obj.rate:0, obj.stock_weight, obj.length_weight, obj.stock_lengths, obj.efficiency, obj.IsFullLength, obj.IsPerKg));
        }
        else{
            updateData(line,{});
            setPrice(line, 0);
        }
        
    }, [item, lengths, kerf, stock, IsPerKg, IsFullLength]);

    function detailSelectors(){
        const {material, profile, section} = item;
        return (<>
        <div className='d-flex justify-content-end'>
            <div className='col-3'>
                <label>Material</label>
                <select className='form-control' value={material==null?"":material} onChange={(e)=>{setItem({...item, material: e.target.value});}}>
                    {material==null?(<option value="">Not Selected</option>):(<></>)}
                    {MATERIALS.map((row, i)=>{
                        return (<option key={i} value={row.Key}>{row.Name}</option>);
                    })}
                </select>
            </div>
            {material!=null?(
            <div className='col-2'>
                <label>Profile</label>
                <select className='form-control' value={profile==null?"":profile} onChange={(e)=>{setItem({...item, section: null, profile: e.target.value})}}>
                    {profile==null?(<option value=""> - </option>):(<></>)}
                    {PROFILES.map((row, i)=>{
                        return (<option key={i} value={row.Key}>{row.Name}</option>);
                    })}
                </select>
            </div>
            ):(<></>)}
            {profile!=null?(
            <div className='col-2'>
                <label>Section</label>
                <select className='form-control' value={section==null?"":section} onChange={(e)=>{setItem({...item, section: e.target.value});}}>
                    {section==null?(<option value=""> - </option>):(<></>)}
                    {getSectionsFromProfile(profile).map((row, i)=>{
                        return (<option key={i} value={row.key}>{row.name}</option>);
                    })}
                </select>
            </div>
            ):(<></>)}
            {material!=null && profile!=null && section !=null?(
            <>
            <div className='col-2'>
                <label className="text-right w-100">Stock Length (mm)</label>
                <input type='text' value={stock} className='float-right w-75 form-control text-right' onChange={(e)=>{
                    if(!isNaN(Number(e.target.value))){
                        setStock(Number(e.target.value));
                    }
                }}/>
            </div>
            <div className='col-2'>
                <label className="text-right w-100">Kerf Width (mm)</label>
                <input type='text' className='w-50 float-right form-control text-right'
                value={kerf}
                onChange={(e)=>{
                    if(!isNaN(Number(e.target.value))){
                        setKerf(Number(e.target.value));
                    }
                }}/>
            </div>
            <div className='col-1'>
                <label className='w-100'>&nbsp;</label>
                <button className='btn btn-success fe fe-plus' type='button' onClick={(e)=>{setLengths([...lengths, {weight: 0,length: 0,qty: 0}])}}></button>
            </div>
            </>
            ):(<></>)}
        </div>
        </>)
    }
    function updateLength(obj, index){
        const updated = lengths.map((c, i) => {
            if (i === index) {
                if(!isNaN(Number(obj.length)) && !isNaN(Number(obj.qty))){
                    return {...c, length: obj.length, qty: obj.qty}
                }
              
            } else {
              return c;
            }
        });
        setLengths(updated);
    }
    function runCutList(){
        const {material, profile, section} = item;
        var list = [];
        var valid = false;
        if(lengths.length!=0 && profile!=null && section!=null){      
            var cutListDetails = new CutList(material);
            for(var i=0; i<lengths.length; i++){
                var obj = {
                    length: Number(lengths[i].length),
                    qty: Number(lengths[i].qty)
                }
                if(!isNaN(obj.length) && !isNaN(obj.qty)){
                    if(obj.length > 0 && obj.qty > 0){
                        list.push(obj);
                        valid = true;
                    }
                }
            }
            if(valid){
                cutListDetails.addLengths(list);
                var _results = cutListDetails.run(stock, kerf);
                _results.stockWeight = calculateLengthWeights(_results.lengths*_results.stock);
                _results.partWeight = calculateLengthWeights(_results.usage);
                _results.efficiency = (_results.usage/(_results.lengths*_results.stock))*100;
                return _results;
            }
        }
        return null;

    }
    function calculateWeights(){
        const {material, profile, section} = item;
        var tempLengths = lengths;
        if(profile!=null && section!=null){
            var profileDetails = getProfileFromKey(profile, section);
            var cutListDetails = new CutList(material);
            var cutProfile = new Profile(profile, profileDetails.section.height, profileDetails.section.width, profileDetails.section.wall_thickness);
            cutProfile.density = cutListDetails.details.material.Density;
            for(let i=0; i<lengths.length; i++){
                cutProfile.length = tempLengths[i].length;
                tempLengths[i].weight = cutProfile.weight;
            }    
        }
        else{
            for(let i=0; i<lengths.length; i++){
                tempLengths[i].weight = 0;
            }    
        }
        return tempLengths;
    }
    function calculateLengthWeights(length){
        const {material, profile, section} = item;
        if(profile!=null && section!=null){
            var profileDetails = getProfileFromKey(profile, section);
            var cutListDetails = new CutList(material);
            var cutProfile = new Profile(profile, profileDetails.section.height, profileDetails.section.width, profileDetails.section.wall_thickness);
            cutProfile.density = cutListDetails.details.material.Density;
            cutProfile.length = length;  
            return cutProfile.weight;
        }
        return 0;
    }
    function lengthDetails(){
        return (
            <>
               <div className='row mt-1'>
                    <div className='col-1'>
                        <h6  className='mt-2 text-uppercase'>Item</h6>
                    </div>
                    <div className='col-5'>
                        <h6  className='text-right mt-2 text-uppercase'>Length (mm)</h6>
                    </div>
                    <div className='col-3'>
                        <h6  className='text-right mt-2 text-uppercase'>Quantity</h6>
                    </div>
                    <div className='col-3'>
                        <h6  className='mt-2 text-uppercase'>Weight (kg)</h6>
                    </div>
                </div>
                {calculateWeights().map((row,i)=>{
                    return (
                    <div className='row' key={i}>
                        <div className='col-2'>
                            <h3 className='mt-3 ml-3'><button className='btn btn-danger fe fe-x p-0 mr-3' type='button' onClick={()=>{setLengths(lengths.filter((a, j)=>{return j!=i}))}}></button>{String.fromCharCode(65 + i)}</h3>
                            
                        </div>
                        <div className='col-4'>
                            <input type='text' className='form-control text-right' placeholder='Length' value={row.length==0?"":row.length} onChange={(e)=>{updateLength({...row, length: e.target.value}, i)}}/>
                        </div>
                        <div className='col-3'>
                            <input type='text' className='form-control text-right' placeholder='Qty' value={row.qty==0?"":row.qty} onChange={(e)=>{updateLength({...row, qty: e.target.value}, i)}}/>
                        </div>
                        <div className='col-3'>
                            <p>{(row.weight*row.qty).toFixed(1)}kg<br/><small>{(row.weight).toFixed(1)}kg each</small></p>
                            
                        </div>
                    </div>
                    );
                })}
            </>
        )
    }
    function optimiserResults(){
        if(results==null)return;
        var allLengths = calculateWeights();
        var totalPartWeights = 0;
        var totalStockWeight = calculateLengthWeights(results.lengths * results.stock);
        for(let i=0; i<allLengths.length; i++){
            totalPartWeights += allLengths[i].weight * allLengths[i].qty;
        }
        var totalExcess = (results.stock*results.lengths)-results.usage;
        return (<>
            <div className='row'>
                <div className='col'>
                    <h6 className='mt-2 text-uppercase'>Lengths Required</h6>
                </div>                
                <div className='col'>
                    <strong>{results.lengths}</strong>
                </div>
                <div className='col'>
                    <h6 className='mt-2 text-uppercase'>No. Of Cuts</h6>
                </div>                
                <div className='col'>
                    <strong>{results.cuts}</strong>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <h6 className='mt-2 text-uppercase'>Part Weights</h6>
                </div>                
                <div className='col'>
                    {totalPartWeights.toFixed(1)}kg
                </div>
                <div className='col'>
                        <h6 className='mt-2 text-uppercase'>Stock Weight</h6>
                    </div>                
                <div className='col'>
                    {totalStockWeight.toFixed(1)}kg
                </div>
            </div>
            
            <div className='row'>
                <div className='col'>
                    <h6 className='mt-2 text-uppercase'>Total Excess</h6>
                </div>                
                <div className='col'>
                    {totalStockWeight!=0?(<>{totalExcess.toFixed(1)}mm</>):(<>NA</>)}
                </div>
                <div className='col'>
                    <h6 className='mt-2 text-uppercase'>Stock Usage</h6>
                </div>                
                <div className='col'>
                    {totalStockWeight!=0?(<>{((totalPartWeights/totalStockWeight)*100).toFixed(1)}%</>):(<>NA</>)}
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="0" checked={!IsPerKg} onChange={()=>{setIsPerKg(false)}}/>
                        <label className="form-check-label">Price Per Length</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="1" checked={IsPerKg} onChange={()=>{setIsPerKg(true)}}/>
                        <label className="form-check-label">Price Per Kg</label>
                    </div>
                    <div className="form-check form-check-inline float-right">
                        <input className="form-check-input" type="checkbox" value="1" checked={IsFullLength} onChange={()=>{setIsFullLength(!IsFullLength)}}/>
                        <label className="form-check-label">Full Length</label>
                    </div>
                </div>
            </div>

            <div className='row border-bottom border-dark'>
                    {results.isComplete?(
                    <div className='col'>                      
                    </div>):(
                    <div className='col'>
                        <p className='alert alert-danger font-weight-light p-3 '>Could not nest all lengths</p>
                    </div>)}

            </div>

            <div className='row' style={{height: "10rem", overflowY: "scroll"}}>
                <div className='col'>
                    <ul className='list-group  m-0 p-0'>
                        {results.a.map((stock, i)=>{
                            var offcut = results.stock - stock.usage
                            return (<li key={i} className='list-group-item m-0 p-0'>
                                <strong>#{i+1} - </strong><small>{stock.distinct}</small>
                                <em className='ml-3'><small>({offcut.toFixed(1)}mm off cut - {stock.cuts} cuts {})</small></em>
                                {(offcut<results.kerf && offcut > 0)?(<p className='alert alert-warning font-weight-light p-1 m-0' style={{fontSize: '0.75rem'}}>Thin cut required to achieve lengths</p>):(<></>)}
                                {(stock.kerf%results.kerf!=0)?(<p className='alert alert-warning font-weight-light p-1 m-0' style={{fontSize: '0.75rem'}}>Thin cut; {(stock.kerf%results.kerf).toFixed(2)}mm to cut off one part</p>):(<></>)}
                            </li>)
                        })}

                    </ul>
                </div>
            </div>

        </>)
    }
    return (
        <>
        {detailSelectors()}
        <div className='row mt-3'>
            <div className='col-6'>
                {optimiserResults()}
            </div>
            <div className='col-6'>
                {lengthDetails()}
            </div>
        </div>

        </>
    );
}

export const CutListRate = ({line, initData, updateData,setPrice}) =>{
    const IsPerKg = initData!==null?initData.IsPerKg:true;
    const [rate, setRate] = useState({
        display: "",
        value: 0
    });
    useEffect(()=>{
        if(initData!=null){
            setTimeout(()=>{
                setRate({
                    display: "$"+initData.rate.toFixed(2)+"/kg",
                    value: initData.rate
                });
            },100);
        }
    }, []);
    useEffect(()=>{
        if(initData!=null){
            const obj = {...initData};
            obj.rate = rate.value;
            setPrice(line, getUnitPricing(obj.rate?obj.rate:0, obj.stock_weight, obj.length_weight, obj.stock_lengths, obj.efficiency, obj.IsFullLength, obj.IsPerKg));
            updateData(line, obj);
        }
        else{
            updateData(line, {rate: rate.value});
        }
    }, [rate]);

    function updateRate(e){
        var newValue = Number(e.target.value);
        if(!isNaN(newValue)){
            setRate({...rate, display: e.target.value, value: newValue});
        }
    }
    function setRateDisplay(e, isFocus){
        if(isFocus){
            setTimeout(()=>{e.target.select();}, 100);
            
            setRate({...rate, display: rate.value});
            
        }
        else{
            if(initData!=null){
                const obj = {...initData};
                if(obj.IsPerKg){
                    setRate({...rate, display: "$"+rate.value.toFixed(2)+"/length"});
                    return;
                }
            }

            setRate({...rate, display: "$"+rate.value.toFixed(2)+"/kg"});
            return;
            
        }
    }
    return (
        <>
        <div className='row'>
            <div className='col'>

            </div>
            <div className='col-5 d-flex justify-content-end'>
                <label className='mt-3 mr-3 mb-0'>{IsPerKg?"Rate ($/kg)":"Rate ($/Length)"}</label><input className='form-control text-right w-50 pr-2' value={rate.display} onChange={updateRate} onFocus={(e)=>{setRateDisplay(e, true)}} onBlur={(e)=>{setRateDisplay(e, false)}} placeholder='Price'/>
            </div>
        </div>
        </>
    );
}