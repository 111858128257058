import React, { useState, useCallback, useEffect } from 'react';


export const PriceItem = ({line, initData, updateData,setPrice}) =>{
    const [quantity, setQuantity] = useState({
        display: "",
        value: 0
    });
    const [unitPrice, setUnitPrice] = useState({
        display: "$ 0.00",
        value: 0
    });
    const [description, setDescription] = useState("");

    useEffect(()=>{
        setPrice(line, quantity.value * unitPrice.value);
        updateData({quantity: quantity.value,   unitPrice: unitPrice.value, description:description});

    }, [quantity, unitPrice]);

    function updateQty(e){
        var str = e.target.value;
        str = str=="."?"0.":str;
        var newValue = Number(str);
        if(!isNaN(newValue)){
            setQuantity({...quantity, display: str, value: newValue});
        }
    }
    function setQtyFocus(e, isFocus){
        if(isFocus){
            setTimeout(()=>{e.target.select();}, 100);   
        }
        setQuantity({...quantity, display: quantity.value.toFixed(2)});
    }
    function updateUnitPrice(e){
        var newValue = Number(e.target.value);
        if(!isNaN(newValue)){
            setUnitPrice({...unitPrice, display: e.target.value, value: newValue});
        }
    }
    function setUnitPriceFocus(e, isFocus){
        if(isFocus){
            setTimeout(()=>{e.target.select();}, 100);
            
            setUnitPrice({...unitPrice, display: unitPrice.value});
            
        }
        else{
            setUnitPrice({...unitPrice, display: "$  "+unitPrice.value.toFixed(2)});
        }
    }
    return (
        <>
        <div className='row'>
            <div className='col-5'>
                <input className='form-control' value={description} onChange={(e)=>{setDescription(e.target.value)}} placeholder='Description'/>
            </div>
            <div className='col-3 d-flex justify-content-end'>
                <label className='mt-3 mr-3 mb-0'>Qty</label><input className='form-control text-right w-50 pr-2' value={quantity.display} onChange={updateQty} onFocus={(e)=>{setQtyFocus(e, true)}} onBlur={(e)=>{setQtyFocus(e, false)}} placeholder='Quantity'/>
            </div>
            <div className='col-4  d-flex justify-content-end'>
                <label className='mt-3 mr-3 mb-0'>Unit Price</label><input className='form-control text-right w-50 pr-2' value={unitPrice.display} onChange={updateUnitPrice} onFocus={(e)=>{setUnitPriceFocus(e, true)}} onBlur={(e)=>{setUnitPriceFocus(e, false)}} placeholder='Unit Price'/>
            </div>
        </div>
        </>
    );
}