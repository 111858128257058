import React, { useState, useCallback, useEffect } from 'react';


export const LabourListItem = ({line, initData, updateData,setPrice}) =>{
    const [hours, setHours] = useState({
        display: "",
        value: 0
    });
    const [rate, setRate] = useState({
        display: "$ 150",
        value: 150
    });
    const [description, setDescription] = useState("");

    useEffect(()=>{
        setPrice(line, hours.value * rate.value);
        updateData({hours: hours.value,rate: rate.value, description:description});

    }, [hours, rate]);

    function updateHours(e){
        var str = e.target.value;
        str = str=="."?"0.":str;
        var newValue = Number(str);
        if(!isNaN(newValue)){
            setHours({...hours, display: str, value: newValue});
        }
    }
    function setHourDisplay(e, isFocus){
        if(isFocus){
            setTimeout(()=>{e.target.select();}, 100);   
        }
        setHours({...hours, display: hours.value.toFixed(2)});
    }
    function updateRate(e){
        var newValue = Number(e.target.value);
        if(!isNaN(newValue)){
            setRate({...rate, display: e.target.value, value: newValue});
        }
    }
    function setRateDisplay(e, isFocus){
        if(isFocus){
            setTimeout(()=>{e.target.select();}, 100);
            
            setRate({...rate, display: rate.value});
            
        }
        else{
            setRate({...rate, display: "$  "+rate.value.toFixed(0)});
        }
    }
    return (
        <>
        <div className='row'>
            <div className='col-5'>
                <input className='form-control' value={description} onChange={(e)=>{setDescription(e.target.value)}} placeholder='Description'/>
            </div>
            <div className='col-3 d-flex justify-content-end'>
                <label className='mt-3 mr-3 mb-0'>Hours</label><input className='form-control text-right w-50 pr-2' value={hours.display} onChange={updateHours} onFocus={(e)=>{setHourDisplay(e, true)}} onBlur={(e)=>{setHourDisplay(e, false)}} placeholder='Hours'/>
            </div>
            <div className='col-3  d-flex justify-content-end'>
                <label className='mt-3 mr-3 mb-0'>Rate</label><input className='form-control text-right w-50 pr-2' value={rate.display} onChange={updateRate} onFocus={(e)=>{setRateDisplay(e, true)}} onBlur={(e)=>{setRateDisplay(e, false)}} placeholder='Rate ($/hr)'/>
            </div>
        </div>
        </>
    );
}