const materials = [
    {
        Key: "GR250MS",
        Name: "GR250 - Mild Steel",
        Density: 7900,
    },
    {
        Key: "GR350MS",
        Name: "GR350 - Mild Steel",
        Density: 7900,
    },
    {
        Key: "304SS",
        Name: "304 - Stainless Steel",   
        Density: 8000,
    },
    {
        Key: "316SS",
        Name: "316 - Stainless Steel",
        Density: 8000,
    },
    {
        Key: "POM",
        Name: "Acetal",
        Density: 1420,
    },
    {
        Key: "PA",
        Name: "Nylon",
        Density: 1140,
    }
]
export default materials;