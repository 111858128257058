

import {Profile, PROFILES} from './Profiles';
import MATERIALS from './Materials'

class CutList {
    KERF = 2;
    STOCK = 8000;
    MAX_ITERATIONS = 100;
    
    details = {
        type: -1,
        material_key: "",
        material: null,
        profile: null,
        cut_list: []
    }
    constructor(Material){
        this.details.material_key = Material;
        this.details.material = this.getMaterialDetails();

    }
    addLengths = (arr) =>{
        for(let i=0; i<arr.length; i++){
            this.details.cut_list.push({qty: arr[i].qty, length: arr[i].length});
        }
        
    }
    addLength = (Length, Qty) =>{
        this.details.cut_list.push({qty: Qty, length: Length});
    }
    setKerf = (k) =>{
        this.KERF = k;
    }
    setStock = (s) =>{
        this.STOCK = s;
    }
    getMaterialDetails = ()=>{
        for(let i=0;i< MATERIALS.length; i++){
            if(MATERIALS[i].Key==this.details.material_key){
                return MATERIALS[i];
            }
        }
        return null;
    }
    getSections = () =>{
        if(this.details.type==-1){
            return [];
        }
        for(let i=0;i< PROFILES.length; i++){
            if(PROFILES[i].Key==this.details.type){
                return PROFILES[i].Sizes;
            }
        }
        return [];
    }
    run = (stock=this.STOCK, kerf=this.KERF) =>{
        let cut_list = this.details.cut_list;
        this.setKerf(kerf);
        this.setStock(stock);

        //let temp_list = JSON.parse(JSON.stringify(cut_list));
        let temp_list = [];

        /*Consolidate duplicate lengths*/
        for(let i=0; i<cut_list.length; i++){
            var found=-1;
            for(let j=0; j<temp_list.length; j++){
                if(cut_list[i].length==temp_list[j].length && found==-1){
                    found = j;
                }
            }
            if(found==-1){
                temp_list.push(cut_list[i]);
                
            }
            else{
                temp_list[found].qty+=cut_list[i].qty;
            }
        }

        /*Sort by longest length first*/
        temp_list.sort( ( a, b ) => {
            if ( a.length < b.length ){return 1;}
            if ( a.length > b.length ){return -1;}
            return 0;
        });
        
        var data = {
            a: [],
            lengths: 0,
            remaining: null,
            isComplete: false,
            kerfWastage: 0,
            usage: 0,
            cuts: 0,
            stock: stock,
            kerf: kerf
        }

        var iterations = 0;
        while(this.partsRemaining(temp_list) > 0 && iterations < this.MAX_ITERATIONS){
            var arrangement = this.getBestArrangement(temp_list);
            if(arrangement!=null){
                data.a.push(arrangement);
                temp_list = this.deductArrangement(temp_list, arrangement);
            }
            iterations++;
        }
        data.remaining = this.partsRemaining(temp_list)==0?null:temp_list;
        data.isComplete = data.remaining==null;
        data.lengths = data.a.length;

        for(var i=0; i<data.a.length; i++){
            data.a[i].distinct = this.distinctParts(data.a[i].parts);
            data.a[i].IsThinCut = data.a[i].kerf+data.a[i].usage==stock;
            data.usage += data.a[i].usage;
            data.kerfWastage += data.a[i].kerf;
            data.cuts += data.a[i].cuts;
        }
        return data;
    }
    distinctParts = (parts) =>{
        var arr = [];
        for(let i=0; i<parts.length; i++){
            var key = "L"+parts[i];
            if(typeof arr[key]==='undefined'){
                arr[key] = 1;
            }
            else{
                arr[key]++;
            }
        }
        var str = "";
        for(var key in arr){
            str += `${arr[key]} x ${key.replace("L","")}, `
        }
        str = str.substring(0, str.length - 2);
        return str;
    }
    deductArrangement = (list, arr) => {
        var newList = [];
        for(var i=0; i<arr.parts.length; i++){
            for(var j=0; j<list.length; j++){
                if(list[j].length==arr.parts[i]){
                    list[j].qty--;
                }
            }
        }
        for(var j=0; j<list.length; j++){
            if(list[j].qty > 0){
                newList.push(list[j]);
            }
        }
        return newList;
    }
    partsRemaining = (list) =>{
        var p = 0;
        list.forEach((i,row)=>{
            p += i.qty;
        })
        return p;
    }
    allPossiblities = (list) => {
        var possible = 1;
        var counts = [];
        
        for(var i=0; i<list.length; i++){
            possible *= list[i].qty+1;
            counts.push(0);
        }
        var possiblities = [];
        for(var i=0; i<possible; i++){
            var n = [];
            for(var j=0; j<counts.length; j++){
                n.push(counts[j]);
            }
            var index = 0;
            counts[index]++;
            for(var j=0; j<list.length-1; j++){
                if(counts[j] > (list[j].qty)){
                    counts[j] = 0;
                    counts[j+1]++;
                }
                
            }
            if(this.validLength(n, list)){
                possiblities.push(n);
            }
            
        }
        return possiblities;
    }
    validLength = (cut, list)=>{
        var length = 0;
        var firstCut = true;

        cut.forEach((v, i)=>{
            length += v*list[i].length;// + (firstCut?v-1:((v)*this.KERF));
            firstCut = false;
        });
        return length <= this.STOCK;
    }
    getBestArrangement = (list)=>{
        var possiblities = this.allPossiblities(list);
        var minRemain = this.STOCK;
        var minRow = null;
        possiblities.forEach((c, row)=>{
            var cutData = {
                cuts: 0,
                parts: [],
                kerf: 0,
                usage: 0,
                remain: 0
            }
            c.forEach((cuts, i)=>{
                var lg = list[i].length;
                for(var j=0; j<cuts; j++){
                    
                    //Full length of stock
                    if(cutData.usage==0 && lg==this.STOCK){
                        cutData.usage += lg;
                    }

                    //Remainder cut, no additional cut required to clean up stock
                    else if(cutData.usage + lg == this.STOCK){
                        cutData.usage += lg;
                    }
                    //If last cut is close to kerf, adjust to be a slither taken off (i.e. less than kerf)
                    else if(cutData.usage + lg >= this.STOCK-this.KERF && cutData.usage + lg <= this.STOCK){
                        var adjustedKerf = this.STOCK - (cutData.usage+lg);
                        cutData.cuts++;
                        cutData.kerf += adjustedKerf;
                        cutData.usage += lg+adjustedKerf;
                    }
                    //Add everything else that fits in stock
                    else if(cutData.usage + lg < this.STOCK-this.KERF){
                        cutData.cuts++;
                        cutData.kerf += this.KERF;
                        cutData.usage += lg+this.KERF;
                    }                   
                    cutData.parts.push(lg);
                } 
            })
            cutData.remain = this.STOCK - cutData.usage;
            if(cutData.remain < minRemain){
                minRemain = cutData.remain;
                minRow = cutData;
            }
        })
        return minRow;
    }
}
export default CutList;